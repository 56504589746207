import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDrgVJrAyTxgK_5Zv4e2RrCt3TjVa_m5U8",
  authDomain: "smart-logistics-a6411.firebaseapp.com",
  projectId: "smart-logistics-a6411",
  storageBucket: "smart-logistics-a6411.appspot.com",
  messagingSenderId: "624991803305",
  appId: "1:624991803305:web:1bb619acee4b0c7fff16b8",
  measurementId: "G-LEP7S5YCPQ",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const firestore = firebase.firestore();
